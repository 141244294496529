export default function homeLabel(lang) {
  let notifikasi = {
    success: {
      message: 'Success',
      description:
        lang === 'eng' ? 'Data saved successfully' : 'Data Berhasil disimpan',
    },
    error: {
      message: 'Error',
      description:
        lang === 'eng'
          ? 'Server Error Occurred'
          : 'Terjadi Kesalahan Pada Server',
    },
  };

  return lang === 'eng'
    ? {
        foundation: 'Loyola Foundation',
        profil: 'Management Profile',
        informasi: 'Information',
        cerita: 'Story of Loyola Alumnus',
        dataKosong: 'Null',
        more: 'Show More',
        notifikasi: notifikasi,
      }
    : {
        foundation: 'Yayasan Loyola',
        profil: 'Profil Pengurus',
        informasi: 'Informasi',
        cerita: 'Cerita KEKL',
        dataKosong: 'Data Kosong',
        more: 'Lebih Lengkap',
        notifikasi: notifikasi,
      };
}
