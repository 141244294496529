import React from 'react';
import Layout from '@components/Layout';
import Home from './Home';

async function action({ token, uId, userRole, language, isMobile }) {
  return {
    title: 'Home Page',
    chunks: ['home'],
    component: (
      <Layout>
        <Home
          token={token}
          UID={uId}
          language={language}
          userRole={userRole}
          isMobile={isMobile}
        />
      </Layout>
    ),
  };
}

export default action;
